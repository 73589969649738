import React, { Component } from 'react';
import VideoModal from './VideoModal.js';
import '../../content/css/videoPlayerModal.css';
export default class VideoPlayer extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	  	videoActive: false,
	  	playingVideo: '',
	  	youtubeURL: 'https://www.youtube.com/embed/',
	  	videoList: [],
	  	videoSeq: 3,
	  };
	}
	closeVideoPlayer(){
		this.setState({
			videoActive: false,
			playingVideo: '',
		})
	}
	handleVideoEnd(){
		this.setState({videoActive: false})
	}
	static getDerivedStateFromProps(props, state) {
		//
		return null
	}
	
	handleVideoStart(e){
		let button = e.target;
		let video = button.getAttribute('data-videoid');
		let videoSeq = button.getAttribute('data-key');
		this.setState({playingVideo: video, videoActive: true, videoSeq: videoSeq})
	}
	render() {
		let items = this.props.videoList.map((item, key) =>
			    <li key={key}>
			    	<button data-videoid={item.videoId} 
			    		data-key={key}
			    		className="landingVideoTab" 
			    		onClick={this.handleVideoStart.bind(this)}
			    	>
			    		{item.tabLabel}
			    	</button>
			    </li>
		);
		let active = this.state.videoActive === true;
		return (
			<div>
				<div className="sphereTabs">		
					<ul>{items}</ul>
				</div>
				{active&&
					<VideoModal videoId={this.state.playingVideo} 
						active={this.state.videoActive} 
						closeVideoModal={this.closeVideoPlayer.bind(this)}
						handleVideoEnd={this.handleVideoEnd.bind(this)}
					/>
				}
			</div>
		);
	}
}
