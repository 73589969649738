import React from 'react';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {Link} from 'gatsby';
let gravURL = process.env.REACT_APP_GRAV_URL;
export default class LandingNavBlocks extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			blocks: [],
		}
	}
	componentDidMount(){
		this.setState({blocks: this.props.blocks,})
	}
	render() {
		let list = this.props.blocks;
		let items = list.map((item, key) =>
			<div className="sm:w-4/12 mx-3 text-center" key={key}>
				<div className="landingNavBlock">
					<Link to={item.url}>
					<div className="landingVideoTab">
						<LazyLoadImage alt={item.title} className="recipeBookImage" src={gravURL + item.picture} threshold={500}/>
						<LazyLoadImage alt={item.title} className="rollOverImage" src={gravURL + item.rollOverImage} threshold={500}/>
						<div className="buttonWrapper">
							<span href={item.url} className="bg-primary inline-block rounded-lg text-white py-1 w-48 shadow-md px-4">
								{item.title}
							</span>
						</div>
					</div>
					</Link>
				</div>
				
			</div>
		);
		return (
			<div className="pageGrid clearfix">
				<div className="sm:flex">{items}</div>
			</div>
		);
	}
}
