import React, { Component } from 'react';
import YouTube from 'react-youtube';
export default class VideoModal extends Component {
	render() {
		const opts = {
	      playerVars: { // https://developers.google.com/youtube/player_parameters
	        autoplay: 1
	      }
	    };
		return (
			<div className={"videoModalWrapper " + this.props.active}>
				<button className="videoModalOverlay" onClick={this.props.closeVideoModal.bind(this)}>
					<span className="hidden">Close</span>
				</button>
				<button className="videoPlayerFrame" onClick={this.props.closeVideoModal.bind(this)}>
					<div className="videoPlayer">
						<div className="embedContainer">
							<YouTube
						        videoId={this.props.videoId}
						        opts={opts}
						        className="videoFrame"
						        onEnd={this.props.handleVideoEnd}   
						    />
						</div>	
					</div>
				</button>
			</div>
		);
	}
}
