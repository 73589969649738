import React from 'react';
import { Link } from 'gatsby';
// import * as routes from '../../constants/routes';
import HtmlParser from 'react-html-parser';
import {LazyLoadImage} from 'react-lazy-load-image-component';	
const SignUpBanner = (props) => {
	let style = {
		background: props.bannerBackground
	}
	let bannerText = HtmlParser(props.bannerText)
	return (
		<div className="signUpBannerWrapper pt-10 lg:pt-0" style={style}>
			<div className="lg:flex">
				<div className="lg:w-6/12">
					<div className="signUpBannerAlign clearf">
						<div className="signUpBannerSphereWrapper">
							<div className="signUpBannerSphere">
								<div className="signUpBannerSphereContent">
									<div>
										<LazyLoadImage src={props.sphereImg} alt="Logo"/>
										<h2>{bannerText}</h2>
										<Link to='/signup'>
											<button className="bg-white rounded-lg py-2 px-8 shadow-sm hover-bg-primary hover:text-white hover:bg-primary hover:shadow-md text-secondary">
												{props.buttonText}
											</button>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="lg:w-6/12">
					<img src={props.bannerImg}  alt={props.bannerImg} className="signUpBannerImg"/>
				</div>
			</div>
		</div>
	);
}
export default SignUpBanner;
