import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import VideoPlayer from './VideoPlayer';

import {animateScroll as scroll } from 'react-scroll'

export default class HomeBanner extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			height: 0,
			scrolled: false,
			scrolledHistory: 'scrolled',
		}
	}
	handleScrollDown(){
		let headerBanner = document.getElementById("headerBannerWrapper");
		let height = headerBanner.innerHeight
		this.setState({heght: height});
		this.setState({scrollHistory: 'scrolled'})
		scroll.scrollTo(800, {
		 	duration: 300,
  			smooth: false,
  		});
	}
	render(){
		let {bannerText, videoList, mobileBannerImage, desktopBannerImage} = this.props;
		let sphereText = ReactHtmlParser(this.props.bannerText);
		
		return (
			<div>
				<div className="headerBannerWrapper clearfix bg-gray-100"  id="headerBannerWrapper">
					{mobileBannerImage&&
						<img src={this.props.mobileBannerImage} alt={sphereText + ' | NewImage Keto'} className="bannerImgMobile" height="800px" width='800px'/>
					}
					{desktopBannerImage&&
						<img src={this.props.desktopBannerImage} alt={sphereText + ' | NewImage Keto'} className="bannerImgDesktop" height="1000px" width='1920px'/>
					}
					<div className="landingSphere">
						<div className="landingSphereTextWrapper">
							<div className="sphereInner">
								{bannerText?
									<h1>{sphereText}</h1>
									:
									<h1>YOUR <strong>KETO</strong> LIFESTYLE</h1>
								}
								{videoList &&
									<VideoPlayer videoList={this.props.videoList}/>
								}
							</div>
							<span></span>{/* used for alignment*/}
						</div>
					</div>   
		        	<div className="bannerScrollContainer">
			          <button 
			          	className="bannerScrollTrigger" 
						  onMouseOver={this.handleScrollDown.bind(this)}
						  onFocus={this.handleScrollDown.bind(this)}
					  >
			          	<div className="scrollDownArrow mx-auto">
				          	<span className="flaticon-down-arrow"></span>
				          	<span className="flaticon-down-arrow"></span>
				          	<span className="flaticon-down-arrow"></span>
				        </div>
						<span className="hidden">Scroll</span>
			         </button>
		          	</div>
				       
					
				</div>
				
			</div>
		);
	}
}
