import React from 'react';
import Helmet from 'react-helmet';
import HomeBanner from './HomeBanner';
import LandingNavBlocks from './LandingNavBlocks';
import SignUpBanner from './SignUpBanner';
import '../../Content/css/home.css';
import '../../Content/css/titlePage.css'
import {Link} from 'gatsby';
import {LazyLoadImage} from 'react-lazy-load-image-component';

let gravURL = process.env.REACT_APP_GRAV_URL;

class Home extends React.Component {
	constructor(props) {
		super(props);
		this._isMounted = false;
		this.state = {
			gravContent: this.props.pageContext.frontmatter,
			headContent: this.props.pageContext.head,
			media: this.props.pageContext.media,
			landingNavBlocks: this.props.pageContext.landingNavBlocks,
			width: 0,
			height: 0,
			loading: true,
			titlePageOpen: false,
			titlePageStatus: 'closed',
			titlePages: this.props.pageContext.titlePage,
			mobileBannerImage: gravURL + this.props.pageContext.media.bannerMobileSelect,
  			desktopBannerImage: gravURL + this.props.pageContext.media.bannerDesktopSelect,
  			signUpBannerDesktop: gravURL + this.props.pageContext.media.signUpBannerDesktop,
			signUpBannerMobile: gravURL + this.props.pageContext.media.signUpBannerMobile,
			mobile: 'init',
		}
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
	}
	componentDidMount(){
		this._isMounted = true;
		if(this._isMounted){
			window.addEventListener('resize', this.updateWindowDimensions.bind(this));
			window.scrollTo(0,0);
			this.updateWindowDimensions();
		}
	}
	componentWillUnmount() {
	  this._isMounted = false;
	  window.removeEventListener('resize', this.updateWindowDimensions);
	}
	updateWindowDimensions() {
	  	if(window.innerWidth > 1000){
		  	this.setState({mobile: false})
		}
		if(window.innerWidth > 100 && window.innerWidth < 1000){
		  		this.setState({mobile: true})
		}
	}
	handleTitlePage(){
		if(this.state.titlePageOpen === true){
			this.setState({titlePageStatus:'closing',})
			setTimeout(()=>{
				this.setState({
					titlePageStatus:'closed',
					titlePageOpen: false,
				})
			},10);
		}
		else{
			this.setState({
				titlePageStatus:'opening',
				titlePageOpen: true,
			})
			setTimeout(()=>{
				this.setState({titlePageStatus:'open'})
			},10);
		}
	}

	render() {
		let titlePage = this.state.titlePageOpen === true;
		const {title,keyWords,facebookTitle,facebookDesc,shareImg,googleDesc} = this.props.pageContext.head;
		let init = this.state.mobile !== 'init'
		let mobile = this.state.mobile === true || init === false;
		const {location} = this.props;
		const {gravContent} = this.state
		return (
			<main>
				<Helmet>
				<title>New Image Keto | Alpha Lipid™ SDII Programme</title>	
				{googleDesc&&<meta name="description" content={googleDesc}/>}
				{keyWords&& <meta name="keywords" content={keyWords.toString()} />}
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="630" />
				{location&&<meta property="og:url" content={location.href} />}
				<meta property="og:title" content={facebookTitle?facebookTitle:title}/>
				<meta name="twitter:title" content={facebookTitle?facebookTitle:title}/>
				<meta property="og:description" content={facebookDesc?facebookDesc:googleDesc} />
				<meta name="twitter:description" content={facebookDesc?facebookDesc:googleDesc}/>
				<meta property="og:image" content={gravURL + shareImg} />
				<meta property="og:image:url" content={gravURL + shareImg}/>
				<meta name="twitter:image" content={gravURL + shareImg}/>
				<meta name="twitter:card" content={gravURL + shareImg}/>
			</Helmet>
				<div style={{position: 'relative'}}>
					<HomeBanner desktopBannerImage={this.state.desktopBannerImage}
						mobileBannerImage={this.state.mobileBannerImage}
						bannerText={gravContent.bannerText}
						videoList={gravContent.bannerTabNav}
					/>
					<TitlePageMenu open={titlePage}
						closeTitlePage={this.handleTitlePage.bind(this)}
						status={this.state.titlePageStatus}
						items={this.state.titlePages}
					/>
					<section style={{paddingTop:'80px'}}>
						<div className="pageGrid text-center pageHeader">
							<button className="mainHeading titlePageTrigger" onClick={this.handleTitlePage.bind(this)}>ALPHA LIPID<span>™</span> SDII<span>™</span></button>
							<h3 className="capitalize subHeading">
								<Link to="/recipes">REAL FOOD<span className="pipeContainer"> &ensp;|&ensp; </span></Link>
								<Link to="/the-science"><span className="pipeContainer"> </span>REAL SCIENCE<span className="pipeContainer"> &ensp;|&ensp; </span></Link>
								<Link to="/about-the-programme"><span className="pipeContainer"> </span>REAL RESULTS</Link>
							</h3>
						</div>
					</section>
					<section>
						<LandingNavBlocks blocks={this.state.landingNavBlocks}/>
					</section>
					<section>
						<div className="pageGrid text-center pageHeader">
							<h2 className="largeTitle orangeText">{gravContent.lifestyleProgrammeHeading}</h2>
							<h3 className="capitalize subHeading">
								<Link to="/recipes">MEAL PLANS<span className="pipeContainer"> &ensp;|&ensp; </span></Link>
								<Link to="/products">PRODUCTS<span className="pipeContainer"> &ensp;|&ensp;</span></Link>
								<Link to="/tips"><span className="pipeContainer"> </span>Tips</Link>
							</h3>
						</div>
					</section>
					<section>
						<LazyLoadImage alt="NEW IMAGE™ KETO ONLINE WEIGHT MANEGEMENT PROGRAMME"  src={gravURL + this.state.media.programmeBanner} threshold={500}/>
					</section>
					<section>
						<div className="pageGrid text-center pageHeader">
						<Link to="/keto-calculator">
							<h2 className="largeTitle orangeText">{gravContent.successTipsHeading}</h2>
							<h3 className="capitalize subHeading">{gravContent.successTipsSubHeading}</h3>
						</Link>
						</div>
					</section>
					<section className="flatBottomSection">
						{mobile?
						<SignUpBanner bannerText={gravContent.signUpBannerText}
							bannerImg={this.state.signUpBannerMobile}
							buttonText={gravContent.signUpBannerButton}
							bannerBackground={gravContent.signUpBannerBackground}
							sphereImg={gravURL + this.state.media.signUpSphere}
						/>
						:
						<SignUpBanner bannerText={gravContent.signUpBannerText}
							bannerImg={this.state.signUpBannerDesktop}
							buttonText={gravContent.signUpBannerButton}
							bannerBackground={gravContent.signUpBannerBackground}
							sphereImg={gravURL + this.state.media.signUpSphere}
						/>
						}
					</section>
				</div>
			</main>
		);
	}
}
const TitlePageMenu = (props) => {
	let {open} = props;
	let items = props.items.map((item, key)=>
		<Link to={item.url} key={key}>
			<img src={gravURL + item.img} alt={item.title}/>
			<div>
				<h3>{item.title}</h3>
				<p>{item.subTitle}</p>
			</div>
		</Link>
								
	)
	return (
		<div>{open&&
			<div className="titlePage" data-status={props.status}>
				<div className="titlePageWrapper">
					<button onClick={props.closeTitlePage} className="flaticon-cancel closeTitlePage titlePageTrigger">
						<span className="hidden">close</span>
					</button>
					<div className="pageGrid titlePageGrid">
						<div className="titlePageMenu">
							<div className="titlePageFlex">{items}</div>
						</div>
					</div>
				</div>
			</div>
			}
		</div>
	);
}
export default Home